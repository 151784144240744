// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import * as ClipboardJS from 'clipboard';
import "channels";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import { iframeResize } from "iframe-resizer";
import * as echarts from 'echarts';
import 'echarts/theme/blue';

window.echarts = echarts;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Clipboard copy button
document.addEventListener("turbolinks:load", function() {
  const clipboard = new ClipboardJS('.btn');
  clipboard.on('success', (e) => {
    const copyButtonDom = e.trigger;
    const tooltip = bootstrap.Tooltip.getOrCreateInstance(copyButtonDom, {
      title: 'コピーしました'
    });
    copyButtonDom.addEventListener('shown.bs.tooltip', (e) => {
      tooltip.disable();
    });
    tooltip.enable();
    tooltip.show();
  });
});

// Generic tooltip
// https://getbootstrap.com/docs/5.0/components/tooltips/#example-enable-tooltips-everywhere
document.addEventListener("turbolinks:load", function() {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  });
});

// Support component names relative to this directory:
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Iframe Resizer
// https://github.com/davidjbradshaw/iframe-resizer
document.addEventListener("turbolinks:load", function() {
  iframeResize({ log: false }, '.iframe-resized');
});

// HACK Rerender charts on modal show
// https://github.com/apache/echarts/issues/10478
// https://github.com/railsjazz/rails_charts/blob/b424bd1eb0a047080afb66a939ae6279f49bb82c/lib/rails_charts/base_chart.rb#L47
document.addEventListener('turbolinks:load', () => {
  // Ones in a modal should be rerendered on modal show
  const instrumentModals = document.querySelectorAll('.instrument-modal, .displayable-index-modal');
  instrumentModals.forEach(instrumentModal => {
    instrumentModal.addEventListener('shown.bs.modal', event => {
      const ohlcChartWrapper = event.target.querySelector('.ohlc-chart');
      if (window.RailsCharts.charts[ohlcChartWrapper.id]) {
        window[`destroy_${ohlcChartWrapper.id}`]();
      }
      window[`init_${ohlcChartWrapper.id}`]();
    });
  });

  // Ones not in a modal should be rerendered on window resize
  window.addEventListener('resize', () => {
    const ohlcChartWrappers = document.querySelectorAll('.ohlc-chart');
    ohlcChartWrappers.forEach(ohlcChartWrapper => {
      if (window.RailsCharts.charts[ohlcChartWrapper.id]) {
        window[`destroy_${ohlcChartWrapper.id}`]();
      }
      window[`init_${ohlcChartWrapper.id}`]();
    });
  });
});